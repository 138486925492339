.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
  }
  
  .modal-close {
    float: right;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }


  @media screen and (max-width: 700px) {
    .modal-content {
        margin: 1rem;
        padding: 1rem;
    }
}