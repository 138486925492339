html, body {
  font-family: 'Istok Web', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

h1, h2 {
  padding: 0;
  margin: 0;
}

hr {
  border: 0;
  height: 1.5px;
  background-color: #DDDDDD;
  margin: 2rem 0 2rem 0;
}

p {
  margin: 0;
  padding: 0;
}

.container {
  padding: 1rem 5rem 1rem 5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  
}

.subcontainer {
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}

.react-transform-wrapper {
  overflow: visible !important;
}

@media screen and (max-width: 700px) {
  .container {
    padding: 0 2rem 0 2rem;
  }

  h1 {
    font-size: 18px;
  }
}