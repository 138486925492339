  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  
  .description {
    flex: 1;
    min-width: 300px;
  }

  .skill-text {
    width: 60%;
  }
  
  .profile-image {
    width: 26%;
    border: 1rem solid var(--secondary-color);
    border-radius: 50%;
  }

  .timeContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 700px) {
    .content {
      flex-wrap: wrap; /* Allow wrapping on smaller screens */
    }
  
    .profile-image {
      width: 70%;
      max-width: 200px;
      margin-top: 2rem;
    }

    .skill-text {
      width: 100%;
    }

    .timeContainer {
      flex-direction: column;
    }

    .timeContainer > p {
      text-align: right;
      margin-left: 0;
      padding-left: 0;
      margin-top: '1rem'
    }
  }
  